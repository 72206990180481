/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Últimamente estaba ya cansado de mi Debian, he trasteado demasiado con él. Por eso decidí probar cosas nuevas, me empiezan a gustar las nuevas versiones de Kubuntu y he acabado poniendome Kubuntu 8.04 con Kde 4, el problema lo tube cuando le puse los efectos, mi grafica no aguanta tanto y cuando reinicié se me quedó toda la pantalla en negro. Y buscando por internet encontre la solución, es bien simple simplemente hay que borrar la carpeta .kde4 de tu usuario y volvera la configuración original, supongo que con todos los escritorios será igual por ejemplo si estamos es gnome borrar la carpeta .gnome2 o en kde 3.5 borrar la carpeta .kde."), "\n", React.createElement(_components.p, null, "Bueno, eso es todo, saludos"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
